import React, { PureComponent, lazy, Suspense } from 'react';
import injectSheet from 'react-jss';

import Header from '../Header';
import styles from './styles';

const LazyFooter = lazy(() => import('../Footer'));

class Content extends PureComponent {
  componentDidMount() {
    // Scroll Restoration - navigation always start from the top
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, title, caption, hideHeader, children } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.wrapper}>
          {hideHeader ? null : <Header title={title} caption={caption} />}
          {children}
          <div className={classes.push} />
        </div>
        <Suspense fallback={null}>
          <LazyFooter />
        </Suspense>
      </div>
    );
  }
}

export default injectSheet(styles)(Content);
