const content = {
  title: 'Signup',
  caption: 'Let me know how I can get in touch with you!',
  askFullName: "What's your full name?",
  askEmail: "What's your email?",
  submit: 'I want a surprise book!',
  erroEmptyFullname: 'Please enter your full name',
  erroEmptyEmail: 'Please enter your email',
  errorInvalidEmail: 'Pleae enter an valid email',
  submitFail: "Something's wrong. Try again!",
};

export default content;
