const styles = (theme) => ({
  content: {
    width: '100%',
    height: '100%',
    padding: `${theme.space.section} 0`,
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100%',
    marginBottom: '-9em',
  },

  push: {
    height: `calc(9em + ${theme.space.section})`,
  },
});

export default styles;
