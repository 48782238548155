const reducer = (
  state = {
    loading: false,
    fullname: '',
    email: '',
    message: '',
  },
  action,
) => {
  switch (action.type) {
    case 'SIGNUP_POST_REQUESTED':
      return Object.assign({}, state, {
        loading: true,
        fullname: action.fullname,
        email: action.email,
        message: '',
      });
    case 'SIGNUP_POST_SUCCESS':
      return Object.assign({}, state, {
        loading: false,
        message: '',
      });
    case 'SIGNUP_POST_FAIL':
      return Object.assign({}, state, {
        loading: false,
        message: action.message,
      });
    default:
      return state;
  }
};

export default reducer;
