import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import injectSheet from 'react-jss';
import loadable from '@loadable/component';

import Content from '../universal/Content';
import Loader from '../universal/Loader';

import styles from './styles';

const LazyNavBar = lazy(() => import('../universal/NavBar'));

const LoadableIntroduction = loadable(() =>
  import(/* webpackPrefetch: true */ '../Introduction'),
);

const LoadableSignup = loadable(() =>
  import(/* webpackPrefetch: true */ '../SignupForm'),
);

const LoadableConfirmation = loadable(() =>
  import(/* webpackPrefetch: true */ '../Confirmation'),
);

const LoadableBookList = loadable(() =>
  import(/* webpackPrefetch: true */ '../BookList'),
);

const LoadableBook = loadable(() =>
  import(/* webpackPrefetch: true */ '../BookDetail'),
);

const LoadableLottery = loadable(() =>
  import(/* webpackPrefetch: true */ '../Lottery'),
);

const CenteredLoader = (
  <Content hideHeader={true}>
    <Loader />
  </Content>
);

const App = ({ classes }) => (
  <div id="outer-container">
    <Suspense fallback={null}>
      <LazyNavBar />
    </Suspense>
    <main id="page-wrap" className={classes.main}>
      <Suspense fallback={CenteredLoader}>
        <Switch>
          <Route exact path="/" component={LoadableIntroduction} />
          <Route exact path="/signup" component={LoadableSignup} />
          <Route exact path="/confirm" component={LoadableConfirmation} />
          <Route exact path="/books" component={LoadableBookList} />
          <Route exact path="/book/:bookId" component={LoadableBook} />
          <Route
            exact
            path="/winner-of-the-month"
            component={LoadableLottery}
          />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </main>
  </div>
);

export default injectSheet(styles)(App);
