const styles = theme => ({
  content: {
    margin: `2em 0`,
    '& h1, & p': {
      margin: 0,
    },

    '& h1': {
      fontWeight: theme.font.weightBold,
    },

    '& p': {
      fontSize: '.9em',
    },

    '& img': {
      display: 'inline-block',
      height: '.5em',
      width: 'auto',
      margin: '0 .2em',
    },
  },
});

export default styles;
