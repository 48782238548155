import React from 'react';
import injectSheet from 'react-jss';

import styles from './styles';
import logo from './logo-black.png';

const Header = ({ classes, title, caption }) => (
  <div className={classes.content}>
    <h1>
      {title}
      <img src={logo} alt="Spes logo" />
    </h1>
    <p>{caption}</p>
  </div>
);

export default injectSheet(styles)(Header);
