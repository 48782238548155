export const signupRequested = ({ fullname, email }) => ({
  type: 'SIGNUP_POST_REQUESTED',
  fullname,
  email,
});

export const signupSuccess = () => ({
  type: 'SIGNUP_POST_SUCCESS',
});

export const signupFail = message => ({
  type: 'SIGNUP_POST_FAIL',
  message,
});
