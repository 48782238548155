import 'whatwg-fetch';

import { getSignupUrl } from './utils/api';
import { encode } from './utils/form';

export default async function post(data) {
  const url = getSignupUrl();
  const signupData = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode(data),
  };
  const response = await fetch(url, signupData);
  if (!response.ok) {
    throw new Error('Signup failed.');
  }
}
