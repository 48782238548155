const initialState = [
  {
    isbn10: '9780345472328',
    title: 'Mindset: The New Psychology of Success',
    authors: [
      {
        name: 'Carol S. Dweck',
        photoSrc: '',
        socialLink: 'https://mindsetonline.com/abouttheauthor/index.html',
        socialLinkType: 'website',
      },
    ],
    link:
      'https://www.amazon.com/Mindset-Psychology-Carol-S-Dweck/dp/0345472322/ref=sr_1_1?ie=UTF8&qid=1543743173&sr=8-1&keywords=mindset',
    description: `Have you ever gotten discouraged by the thought of failing and stopped pursuing something? I have. It happens more than I'd like to admit. I don’t remember exactly how I discovered this book but it has completely changed my attitude towards my life and career. I stopped fixating on my natural abilities and started to believe that I can learn my way to become the best of me. Not only that it frees me to pursue my passions and try out more new things, I’ve been enjoying the process so much more. I highly recommend you to read the book:)`,
    quote: `No matter what your ability is, effort is what ignites that ability and turns it into accomplishment.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `I love learning and growing as a person! The best thing about “Mindset” is that it doesn’t just explain how I can adapt myself to growth mindset, but also how I can help others to adapt too!`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: 1543708800000,
    creteAt: 1543708800000,
    updateAt: 1543708800000,
    attributes: [
      {
        title: 'Inspiration',
        value: 5,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 5,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 2,
        comment: '',
      },
      {
        title: 'Communication',
        value: 4,
        comment: '',
      },
      {
        title: 'Health',
        value: 3,
        comment: '',
      },
    ],
    highlight: true,
  },
  {
    isbn10: '9781250183866',
    title: 'Extreme Ownership: How U.S. Navy SEALs Lead and Win',
    authors: [
      {
        name: 'Jocko Willink',
        photoSrc: '',
        socialLink: 'https://twitter.com/jockowillink',
        socialLinkType: 'twitter',
      },
      {
        name: 'Leif Babin',
        photoSrc: '',
        socialLink: 'https://twitter.com/LeifBabin',
        socialLinkType: 'twitter',
      },
    ],
    link:
      'https://www.amazon.com/Extreme-Ownership-U-S-Navy-SEALs/dp/1250183863/ref=sr_1_1?ie=UTF8&qid=1542705358&sr=8-1&keywords=extreme+ownership',
    description: `I picked up this book because I wanted to be learn how to be a good leader. After reading though, I learnt to be a happier professional, partner, and son. I didn’t know how to deal with my frustrations and thought I was a victim under circumstances. By exercising extreme ownership, I reclaim control over my emotions and I started to feel gratitude in my life. This is the book to read to break through obstacles in your career and in life.`,
    quote: `Any team, in any organization, all responsibility for success and failure rests with the leader. The leader must own everything in his or her world. There is no one else to blame. The leader must acknowledge mistakes and admit failures, take ownership of them, and develop a plan to win.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `After reading the book, I learn to own the responsibility and empathy to talk to my boss and my team! It’s an amazing feeling to influence people around me and share the same vision with them.`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: null,
    creteAt: 1542672000000,
    updateAt: 1542672000000,
    attributes: [
      {
        title: 'Inspiration',
        value: 4,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 4,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 2,
        comment: '',
      },
      {
        title: 'Communication',
        value: 5,
        comment: '',
      },
      {
        title: 'Health',
        value: 2,
        comment: '',
      },
    ],
    highlight: false,
  },
  {
    isbn10: '1591848288',
    title:
      'The Go-Giver, Expanded Edition: A Little Story About a Powerful Business Idea',
    authors: [
      {
        name: 'Bob Burg',
        photoSrc: '',
        socialLink: 'https://twitter.com/BobBurg?lang=en',
        socialLinkType: 'twitter',
      },
      {
        name: 'John David Mann',
        photoSrc: '',
        socialLink: 'https://twitter.com/JohnDavidMann?lang=en',
        socialLinkType: 'twitter',
      },
    ],
    link:
      'https://www.amazon.com/gp/product/1591848288/ref=as_li_tl?ie=UTF8&tag=spes0d-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1591848288&linkId=0b4db8e9a6639016ddc06b643fef7d0f',
    description: `It's easy to forget to be considerate and kind at work from time to time. This book reminds you of that and paints a picture why we all depend on each other. Our successes as well.`,
    quote: `Your influence is determined by how abundantly you place other people’s interest first.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `I read the book all the way to the end without a break. It's such a bright, hopeful, and vivid story that touches your memory of the values you were taught in kindergarden.`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: 1527811200000,
    creteAt: 1527811200000,
    updateAt: 1527811200000,
    attributes: [
      {
        title: 'Inspiration',
        value: 5,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 5,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 1,
        comment: '',
      },
      {
        title: 'Communication',
        value: 3,
        comment: '',
      },
      {
        title: 'Health',
        value: 1,
        comment: '',
      },
    ],
    highlight: false,
  },
  {
    isbn10: '1885167776',
    title:
      'The ONE Thing: The Surprisingly Simple Truth Behind Extraordinary Results',
    authors: [
      {
        name: 'Gary Keller',
        photoSrc: '',
        socialLink: 'https://www.facebook.com/garykeller/',
        socialLinkType: 'facebook',
      },
    ],
    link:
      'https://www.amazon.com/gp/product/1885167776/ref=as_li_tl?ie=UTF8&tag=spes0d-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1885167776&linkId=f6f8bdd052f419598a94cb1d8f0f2497',
    description: `If you ever thought that your dream is too big or your responsities are too overwhelming, this can be the book for you. I had experienced a few years of mental uneasiness because of the unfufilled goals in my head. This book helped me untangle my thoughts by showing me the importance of priority and focusing on one thing at a time.`,
    quote: `It is not that we have too little time to do all the things we need to do , it is that we feel the need to do too many things in the time we have.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `I've learned that the ONE thing can be your most ambitious dream and all you need is a clear sense of priority to achieve it!`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: 1522540800000,
    creteAt: 1522049265000,
    updateAt: 1522049265000,
    attributes: [
      {
        title: 'Inspiration',
        value: 4,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 3,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 5,
        comment: '',
      },
      {
        title: 'Communication',
        value: 2,
        comment: '',
      },
      {
        title: 'Health',
        value: 1,
        comment: '',
      },
    ],
    highlight: false,
  },
  {
    isbn10: '0307463745',
    title: 'Rework',
    authors: [
      {
        name: 'Jason Fried',
        photoSrc: '',
        socialLink: 'https://twitter.com/jasonfried',
        socialLinkType: 'twitter',
      },
      {
        name: 'David Heinemeier Hansson',
        photoSrc: '',
        socialLink: 'https://twitter.com/dhh',
        socialLinkType: 'twitter',
      },
    ],
    link:
      'https://www.amazon.com/gp/product/0307463745/ref=as_li_tl?ie=UTF8&tag=spes0d-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0307463745&linkId=0ee1a60db3ef32369ae50cb06faca90b',
    description: `If you have an idea on the back of your head but aren't quite sure how to go about it, or you find yourself looking for reasons to procrastinate, try this book! It offers readers a lean mindset to approach their ideas and businesses with the relatable reasonings and a note of encouragement.`,
    quote: `As you get going, keep in mind why you're doing what you're doing. Great businesses have a point of view, not just a product or service. You have to believe in something. You need to have a backbone. You need to know what you're willing to fight for. And then you need to show the world.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `I read it the third time when I decided to give away this book. It's full of great advices for my work and my daily life!`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: 1522540800000,
    creteAt: 1522049265000,
    updateAt: 1522049265000,
    attributes: [
      {
        title: 'Inspiration',
        value: 4,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 3,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 4,
        comment: '',
      },
      {
        title: 'Communication',
        value: 3,
        comment: '',
      },
      {
        title: 'Health',
        value: 1,
        comment: '',
      },
    ],
    highlight: false,
  },
  {
    isbn10: '081298160X',
    title: 'The Power of Habit: Why We Do What We Do in Life and Business',
    authors: [
      {
        name: 'Charles Duhigg',
        photoSrc: '',
        socialLink: 'https://twitter.com/cduhigg',
        socialLinkType: 'twitter',
      },
    ],
    link:
      'https://www.amazon.com/gp/product/081298160X/ref=as_li_tl?ie=UTF8&tag=spes0d-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=081298160X&linkId=afa3e7205c2a1396e6db77c91c9d540a',
    description: `Are you still making New Year's resolutions? Have they ever lasted more than 3 months for you? If not, maybe it's worth a try to look at setting up goals in a fresh way! The author lays out scientific studies and world class businesses to show how habits can transform one's life and benefit business' culture.`,
    quote: `Champions don’t do extraordinary things. They do ordinary things, but they do them without thinking, too fast for the other team to react. They follow the habits they’ve learned.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `This book is the reason that I'm amazingly happy everyday and it gives me the roadmap to fight hardships.`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: 1519862400000,
    creteAt: 1521526710000,
    updateAt: 1521526710000,
    attributes: [
      {
        title: 'Inspiration',
        value: 5,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 4,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 4,
        comment: '',
      },
      {
        title: 'Communication',
        value: 1,
        comment: '',
      },
      {
        title: 'Health',
        value: 3,
        comment: '',
      },
    ],
    highlight: false,
  },
  {
    isbn10: '1328683788',
    title:
      'Tools of Titans: The Tactics, Routines, and Habits of Billionaires, Icons, and World-Class Performers',
    authors: [
      {
        name: 'Timothy Ferriss',
        photoSrc: '',
        socialLink: 'https://twitter.com/tferriss',
        socialLinkType: 'twitter',
      },
    ],
    link:
      'https://www.amazon.com/gp/product/1328683788/ref=as_li_tl?ie=UTF8&tag=spes0d-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1328683788&linkId=a1338ddf3b31934fdaa520369e7fd173',
    description: `This is absolutely a book of wisdoms. It shows readers the ways to handle health, improve productivity, and rethrive mindset by examples of an incredible amount of successful personals in many fields. It's like picking their brains during your bedtime reading!`,
    quote: `The most important trick to be happy is to realize that happiness is a choice that you make and a skill that you develop. You choose to be happy, and then you work at it. It’s just like building muscles.`,
    recommender: 'Daw-Chih Liou',
    recommenderQuote: `It's a great reference for me when in doubt or looking for guidance and inspirations.`,
    recommenderLink: 'https://twitter.com/dawchihliou',
    recommenderLinkType: 'twitter',
    recommenderPhotoSrc: '',
    giveAwayAt: 1517443200000,
    creteAt: 1521526710000,
    updateAt: 1521526710000,
    attributes: [
      {
        title: 'Inspiration',
        value: 5,
        comment: '',
      },
      {
        title: 'Happiness',
        value: 5,
        comment: '',
      },
      {
        title: 'Productivity',
        value: 5,
        comment: '',
      },
      {
        title: 'Communication',
        value: 2,
        comment: '',
      },
      {
        title: 'Health',
        value: 5,
        comment: '',
      },
    ],
    highlight: false,
  },
];

export default initialState;
