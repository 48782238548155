export function getSignupUrl() {
  let signupUrl;

  switch (process.env.NODE_ENV) {
    case 'development':
      signupUrl = '/';
      break;
    case 'staging':
      signupUrl = '/';
      break;
    case 'production':
      signupUrl = '/';
      break;
    default:
      signupUrl = '/';
  }

  return signupUrl;
}

export function fakeSuccessfulPost() {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 1500, 'Fake api call successful');
  });
}

export function fakeFailedPost() {
  return new Promise((resolve, reject) => {
    setTimeout(reject, 1500, 'Fake api call failed');
  });
}
