import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { signupSuccess, signupFail } from './actions';
import post from './api';

import content from './view/content';

export function* postSignup(action) {
  const signupData = {
    'form-name': 'signup', // hardcoded for Netlify Form
    fullname: action.fullname,
    email: action.email,
  };

  try {
    yield call(post, signupData);
    yield put(signupSuccess());
    yield put(push('/confirm'));
  } catch (e) {
    yield put(signupFail(content.submitFail));
  }
}
