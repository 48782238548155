const theme = {
  color: {
    primary: '#202020',
    secondary: '#bbbbbb',
    highlight: '#4FC3F7',
    inactive: '#202020',
    hover: '#202020',
    white: '#ffffff',
    warning: '#fc353f',
  },

  space: {
    section: '1.5em',
  },

  font: {
    family: 'Open Sans, sans-serif',
    size: '1em',
    weightLight: 300,
    weightBold: 500,
    small: '.9em',
  },

  transition: {
    cubic: 'cubic-bezier(0.000, 0.795, 0.000, 1.000)',
  },
};

export default theme;
