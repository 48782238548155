import 'typeface-open-sans';

const styles = (theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
    },

    'html, body, #root, #outer-container': {
      width: '100%',
      height: '100%',
    },

    body: {
      color: theme.color.primary,
      lineHeight: 1.5,
      position: 'relative',
      textAlign: 'left',
      font: {
        family: theme.font.family,
        size: theme.font.size,
        weight: theme.font.weightLight,
      },
    },

    'button, input, optgroup, select, textarea': {
      lineHeight: '1.5 !important',
    },

    h1: {
      fontWeight: theme.font.weightBold,
      fontSize: '1.5em !important',

      '@media only screen and (min-width: 768px)': {
        fontSize: '2em !important',
      },
    },

    h2: {
      fontSize: '1.25em',
      fontWeight: theme.font.weightBold,
    },

    '#page-wrap': {
      transition: 'all .3s ease 0s !important',
    },
  },

  main: {
    width: '80%',
    height: '100%',
    maxWidth: '40em',
    margin: `0 auto`,

    '@media only screen and (min-width: 768px)': {
      width: '50%',
    },

    '@media only screen and (min-width: 1200px)': {
      width: '37.5%',
    },
  },
});

export default styles;
