import React from 'react';
import injectSheet from 'react-jss';

import styles from './styles';

const Loader = ({ classes }) => (
  <div className={classes.loader}>
    <div className={classes.bounce1} />
    <div className={classes.bounce2} />
    <div className={classes.bounce3} />
  </div>
);

export default injectSheet(styles)(Loader);
