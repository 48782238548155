import { escape, trim, isEmail, normalizeEmail } from 'validator';

const sanitize = (str) => escape(trim(str));

export const sanitizeSignupForm = ({ fullname, email }) => ({
  fullname: sanitize(fullname),
  email: sanitize(email),
});

export const validateSignupForm = ({ fullname, email }) => {
  const result = {
    fullname: {
      value: fullname,
      validity: 'valid',
    },
    email: {
      value: email,
      validity: 'valid',
    },
  };

  if (!fullname) {
    result.fullname.validity = 'empty';
  }

  if (!email) {
    result.email.validity = 'empty';
  } else if (!isEmail(email)) {
    result.email.validity = 'invalid';
  } else {
    result.email.value = normalizeEmail(email);
  }

  return result;
};

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};
