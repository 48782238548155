const styles = (theme) => ({
  loader: {
    margin: '0 auto',
    width: '3em',
    textAlign: 'center',
  },
  dot: {
    width: '.7em',
    height: '.7em',
    backgroundColor: theme.color.primary,
    borderRadius: '100%',
    display: 'inline-block',
    animation: 'bounce 1.4s infinite ease-in-out both',
  },
  bounce1: {
    composes: ['$dot'],
    animationDelay: '-0.32s',
  },
  bounce2: {
    composes: ['$dot'],
    animationDelay: '-0.16s',
  },
  bounce3: {
    composes: ['$dot'],
    animationDelay: '0s',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1.0)',
    },
    '80%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
});

export default styles;
